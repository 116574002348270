import React from 'react';

import { Layout } from '../../components/layouts';
import { SEO } from '../../components/common';
import { GrabPayReturnSection } from '../../components/sections';
import { CountryCode } from '../../utils/constants';

const GrabPayPage = (): JSX.Element => (
  <Layout hideFooter hideHeader countryCode={CountryCode.SG}>
    <SEO title="GrabPay Payment Result" />
    <GrabPayReturnSection />
  </Layout>
);

export default GrabPayPage;
